<template>
  <div id="login" class="login">
    <form @submit.prevent="handleSubmit">
      <error v-if="error" :error="error" />

      <div class="upper-part" style="height: 10vw">
        <b>SystemTee</b>
      </div>
      <br />
      <!-- <h3><b>Login</b></h3> -->
      <div class="form-group">
        <label><b>Username</b></label
        ><input
          type="userName"
          class="form-control"
          v-model="userName"
          placeholders="text"
        />
      </div>

      <div class="form-group">
        <label><b>Password</b></label
        ><input
          type="password"
          class="form-control"
          v-model="password"
          placeholders="Password"
        />
      </div>
      <div class="spinner-border text-primary" v-if="loadingLogin"></div>
      <button class="btn btn-primary btn-block">Login</button>
    </form>
  </div>
</template>

<script>
import Error from "./Error.vue";
import axios from "axios";
export default {
  name: "Login",
  data() {
    return {
      loadingLogin: false,
      userName: "",
      password: "",
      error: "",
    };
  },
  components: { Error },
  methods: {
    async handleSubmit() {
      try {
        this.loadingLogin = true;
        const data = {
          userName: this.userName,
          password: this.password,
        };

        const BaseUrl = process.env.VUE_APP_BASE_URL;
        const response = await axios.post(
          `${BaseUrl}/api/Authentication/login`,
          data
        );

        this.$store
          .dispatch("user/postUser", { user: response.data })
          .then(() => {
            this.$store.dispatch("login");
            this.loadingLogin = false;
            this.$router.push("/dashboard");
          });
      } catch (e) {
        this.loadingLogin = false;
        this.error = "Invalid username or password!";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login h3 {
  text-align: center;
}
.upper-part {
  display: flex;
  justify-content: center;
  align-items: center;
  // grid-template-rows: 9fr 1fr;
  border-radius: 7px;
  border-style: solid;
  border-width: 1px;
  --primary: #8a57e963;
  --secondary: #4c75e625;
  background-image: linear-gradient(
    to right bottom,
    var(--primary),
    var(--secondary)
  );
  color: white;
  .title {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}
</style>
